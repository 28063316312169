import React from "react";
import MaintenanceLayer from "../components/MaintenanceLayer";

const MaintenancePage = () => {
  return (
    <>
      <MaintenanceLayer />
    </>
  );
};

export default MaintenancePage;

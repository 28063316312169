import React from "react";
import AccessDeniedLayer from "../components/AccessDeniedLayer";

const AccessDeniedPage = () => {
  return (
    <>
      <AccessDeniedLayer />
    </>
  );
};

export default AccessDeniedPage;

import React from "react";
import ComingSoonLayer from "../components/ComingSoonLayer";

const ComingSoonPage = () => {
  return (
    <>
      <ComingSoonLayer />
    </>
  );
};

export default ComingSoonPage;
